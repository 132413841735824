import { SAMPLE_UNIT_PRICE, TESTER_UNIT_PRICE } from "../constants";
import { CONVERSION_FREE_CURRENCIES } from "../constants/s3";
import {
  CustomerType,
  OrderItemsType,
  OrderSheet,
  ProductForTable,
} from "../types/order-sheet";
import { roundPrice } from "./round-price";

export function updateCalculatedValues(products: ProductForTable[], currency: string, exchangeRateFactor: number, discount: number | null, pricesIncludeDiscount: boolean) {
  const tempPrices = {
    products: 0,
    samples: 0,
    testers: 0
  };
  for (const data of products) {
    const totalPrices = getTotalRowPrice(data, currency, exchangeRateFactor);
    data.calculatedQty = getTotalRowQty(data);
    data.calculatedPriceWithoutDiscountApplied = totalPrices.productPrice + totalPrices.samplePrice + totalPrices.testerPrice
    if (discount && !pricesIncludeDiscount) {
      data.calculatedPriceWithPotentialDiscountApplied = roundPrice(totalPrices.productPrice * discount + totalPrices.samplePrice + totalPrices.testerPrice, 2)
    } else {
      data.calculatedPriceWithPotentialDiscountApplied = data.calculatedPriceWithoutDiscountApplied
    }

    tempPrices.products += totalPrices.productPrice;
    tempPrices.samples += totalPrices.samplePrice;
    tempPrices.testers += totalPrices.testerPrice;
  }
  return tempPrices
}

export const getPriceWithOrdersheetStoreSync = (
  ordersheetData: OrderSheet,
  orderItems: OrderItemsType,
  currency: string,
  exchangeRateFactor: number
) => {
  const formattedData : ProductForTable[] = ordersheetData.products.flatMap(product => {
    return product.variants.map(variant=> {
      const orderItem =  orderItems[variant.productId];
      const orderQty = (typeof orderItem === 'object' ? orderItem.qty : orderItem) ?? 0;
      const sampleQty = (typeof orderItem === 'object' ? orderItem.qtySample : orderItem) ?? 0;
      const testerQty = (typeof orderItem === 'object' ? orderItem.qtyTester : orderItem) ?? 0;

      const data =  {
        category: product.category,
        thumbnail: variant.thumbnail,
        name: product.name,
        brand: product.brand,
        color: variant.color,
        colorName: variant.name,
        exactColorName: variant.exactColorName,
        productId: product.id,
        refManufacturer: product.refManufacturer,
        specialEdition: product.specialEdition,
        variantProductId: variant.productId,
        isSample: variant?.eanSample ? 1 : 0,
        isTester: variant?.eanTester ? 1 : 0,
        ean: variant.ean,
        price: roundPrice(
          product.prices[
            (ordersheetData.exportOptions.customerType ===
            CustomerType.DISTRIBUTOR
              ? "distributor"
              : "direct") as CustomerType
          ],
          2
        ),
        moq: product.moq,
        msrp: product.prices["msrp" as CustomerType],
        orderQty,
        sampleQty,
        testerQty,
        calculatedQty: 0,
        calculatedPriceWithoutDiscountApplied: 0,
        calculatedPriceWithPotentialDiscountApplied: 0,
        customerProductCode: variant.customerProductCode,
      };

      return data
    })
  })
  const tempPrices = updateCalculatedValues(formattedData, currency, exchangeRateFactor, ordersheetData.exportOptions.discount ?? null, ordersheetData.productPricesDontIncludeDiscount !== true)
  return {
    formattedData,
    priceComposition: tempPrices
  };
};

export const getPricePerUnit = (product : ProductForTable, currency: string, exchangeRateFactor: number) => {
  let pricePerUnit = roundPrice(product.price, 2);
  if (CONVERSION_FREE_CURRENCIES.includes(currency) === false)
    pricePerUnit = roundPrice(pricePerUnit / (exchangeRateFactor || 1), 2);
  return pricePerUnit;
}

export const getPricePerUnitSample = (currency: string, exchangeRateFactor: number) => {
  let pricePerUnit = roundPrice(SAMPLE_UNIT_PRICE, 2);
  return pricePerUnit;
}

export const getPricePerUnitTester = (currency: string, exchangeRateFactor: number) => {
  return 0;
}

export const getTotalRowPrice = (product : ProductForTable, currency: string, exchangeRateFactor: number) => {
  const orderQty = product.orderQty;
  const sampleQty = product.sampleQty;
  const testerQty = product.testerQty;
  const moq = product.moq;
  const productPrice = roundPrice(orderQty * moq * getPricePerUnit(product, currency, exchangeRateFactor), 2);
  const samplePrice = roundPrice(sampleQty * getPricePerUnitSample(currency, exchangeRateFactor), 2);
  const testerPrice = roundPrice(testerQty * getPricePerUnitTester(currency, exchangeRateFactor), 2);

  return {
    productPrice,
    samplePrice,
    testerPrice
  }
}

export const getTotalRowQty = (product : ProductForTable, withAdditions = true) => {
  const orderQty = product.orderQty;
  const sampleQty = product.sampleQty;
  const testerQty = product.testerQty;
  const moq = product.moq;

  return (orderQty * moq) + (withAdditions ? sampleQty + testerQty : 0);
}

